import React from "react";
import './shimmer.css';

const ShimmerLoader = () => {
  return (
    <div className="shimmer-card shimmer bg-slate-300"></div>
  );
};

export default ShimmerLoader;
