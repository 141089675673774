import React from "react";
import PropTypes from "prop-types";
import BlinksSVGLogo from "./BlinksSVGLogo";

const MediaPlayerSVG = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 823.12732 756.22564"
    >
      <path
        d="M720.73536,244.83443h-3.99878V135.2891a63.40183,63.40183,0,0,0-63.40179-63.40192H421.24806a63.40185,63.40185,0,0,0-63.402,63.40172V736.26331a63.40188,63.40188,0,0,0,63.4018,63.40192H653.33449a63.40187,63.40187,0,0,0,63.402-63.40168V322.81067h3.99884Z"
        transform="translate(-188.43634 -71.88718)"
        fill="#e6e6e6"
      />
      <path
        d="M655.89283,88.38216h-30.295a22.49484,22.49484,0,0,1-20.82715,30.99052H471.81123a22.49482,22.49482,0,0,1-20.82715-30.99056H422.68849a47.34781,47.34781,0,0,0-47.34784,47.34774V735.82245a47.3478,47.3478,0,0,0,47.34778,47.34784h233.2044a47.3478,47.3478,0,0,0,47.34784-47.34778h0V135.72991A47.34777,47.34777,0,0,0,655.89283,88.38216Z"
        transform="translate(-188.43634 -71.88718)"
        fill="#fff"
      />
      <polygon
        points="306.127 751.864 303.127 751.864 309.127 350.864 312.127 350.864 306.127 751.864"
        fill="#3f3d56"
      />
      <polygon
        points="388.127 751.864 385.127 751.864 379.127 350.864 382.127 350.864 388.127 751.864"
        fill="#3f3d56"
      />
      <rect x="310.12732" y="358.86444" width="70" height="3" fill="#3f3d56" />
      <rect x="310.12732" y="410.86444" width="70" height="3" fill="#3f3d56" />
      <rect x="309.12732" y="462.86444" width="73" height="3" fill="#3f3d56" />
      <rect x="309.12732" y="514.86444" width="74" height="3" fill="#3f3d56" />
      <rect x="308.12732" y="566.86444" width="76" height="3" fill="#3f3d56" />
      <rect x="307.12732" y="618.86444" width="77" height="3" fill="#3f3d56" />
      <rect x="306.12732" y="670.86444" width="79" height="3" fill="#3f3d56" />
      <rect x="304.12732" y="722.86444" width="82" height="3" fill="#3f3d56" />
      <circle cx="341.7944" cy="504.2174" r="42.01233" fill="#2f2e41" />
      <rect
        x="544.31827"
        y="606.09253"
        width="12.76324"
        height="22.86749"
        transform="matrix(0.98401, -0.17813, 0.17813, 0.98401, -289.63076, 36.08746)"
        fill="#2f2e41"
      />
      <rect
        x="519.20011"
        y="610.63958"
        width="12.76324"
        height="22.86749"
        transform="matrix(0.98401, -0.17813, 0.17813, 0.98401, -290.84248, 31.6858)"
        fill="#2f2e41"
      />
      <ellipse
        cx="525.42828"
        cy="636.15279"
        rx="10.63602"
        ry="3.98853"
        transform="translate(-475.09373 418.38172) rotate(-40.26106)"
        fill="#2f2e41"
      />
      <ellipse
        cx="550.02321"
        cy="631.70037"
        rx="10.63605"
        ry="3.98853"
        transform="translate(-466.38991 433.22197) rotate(-40.26106)"
        fill="#2f2e41"
      />
      <circle cx="338.85318" cy="493.94094" r="14.35864" fill="#fff" />
      <circle cx="332.95181" cy="488.79416" r="4.78622" fill="#3f3d56" />
      <path
        d="M563.37259,530.39966c.63177-15.55359-12.77313-28.7276-29.9408-29.42493s-31.59692,11.346-32.22873,26.8996,11.30191,19.08746,28.46955,19.78485S562.74081,545.95325,563.37259,530.39966Z"
        transform="translate(-188.43634 -71.88718)"
        fill="#3b82f6"
      />
      <ellipse
        cx="491.43815"
        cy="568.45065"
        rx="6.59448"
        ry="21.00616"
        transform="translate(-360.87753 848.57458) rotate(-77.0899)"
        fill="#2f2e41"
      />
      <ellipse
        cx="573.90679"
        cy="582.16512"
        rx="6.59448"
        ry="21.00616"
        transform="translate(-310.20199 939.60891) rotate(-77.0899)"
        fill="#2f2e41"
      />
      <path
        d="M541.76944,592.66113a9.57243,9.57243,0,1,1-18.8353,3.42884l-.00335-.0185c-.94175-5.20215,3.08038-7.043,8.28256-7.98474S540.82773,587.459,541.76944,592.66113Z"
        transform="translate(-188.43634 -71.88718)"
        fill="#fff"
      />
      <path
        d="M675.0911,327.12766,406.16849,351.22949a5.00588,5.00588,0,0,1-5.42636-4.53371l-5.97864-66.7082a5.00588,5.00588,0,0,1,4.53371-5.42637l268.9226-24.10183a5.00589,5.00589,0,0,1,5.42637,4.53371l5.97864,66.70821A5.00588,5.00588,0,0,1,675.0911,327.12766ZM399.47573,276.55322a3.00328,3.00328,0,0,0-2.72022,3.25582l5.97863,66.70821a3.00329,3.00329,0,0,0,3.25582,2.72023l268.9226-24.10183a3.00328,3.00328,0,0,0,2.72023-3.25582l-5.97863-66.70821a3.0033,3.0033,0,0,0-3.25583-2.72023Z"
        transform="translate(-188.43634 -71.88718)"
        fill="#e6e6e6"
      />
      <circle cx="249.15676" cy="237.88361" r="21" fill="#3f3d56" />
      <path
        d="M484.64976,291.49728a3.5,3.5,0,0,0,.62486,6.97206l164.34129-14.72887a3.5,3.5,0,1,0-.62486-6.97206Z"
        transform="translate(-188.43634 -71.88718)"
        fill="#e6e6e6"
      />
      <path
        d="M486.52434,312.41345a3.5,3.5,0,1,0,.62486,6.972l164.3413-14.72887a3.5,3.5,0,0,0-.62487-6.97205Z"
        transform="translate(-188.43634 -71.88718)"
        fill="#e6e6e6"
      />
      <ellipse
        cx="570.62227"
        cy="340.38066"
        rx="21.53369"
        ry="6.76007"
        transform="translate(-139.48994 679.9823) rotate(-69.08217)"
        fill="#2f2e41"
      />
      <circle
        cx="530.81509"
        cy="372.90266"
        r="43.06735"
        transform="translate(-110.73595 765.24401) rotate(-80.78252)"
        fill="#2f2e41"
      />
      <rect
        x="322.75315"
        y="334.81517"
        width="13.08374"
        height="23.44171"
        fill="#2f2e41"
      />
      <rect
        x="348.92063"
        y="334.81517"
        width="13.08374"
        height="23.44171"
        fill="#2f2e41"
      />
      <ellipse
        cx="333.65629"
        cy="358.52946"
        rx="10.90314"
        ry="4.08868"
        fill="#2f2e41"
      />
      <ellipse
        cx="359.82377"
        cy="357.98432"
        rx="10.90314"
        ry="4.08868"
        fill="#2f2e41"
      />
      <path
        d="M489.03837,332.85127c-3.47748-15.57379,7.63867-31.31045,24.82861-35.14883s33.94422,5.67508,37.42169,21.24887-7.91491,21.31765-25.10485,25.156S492.51585,348.42509,489.03837,332.85127Z"
        transform="translate(-188.43634 -71.88718)"
        fill="#3b82f6"
      />
      <ellipse
        cx="482.30694"
        cy="352.91926"
        rx="6.76007"
        ry="21.53368"
        transform="translate(-231.68444 565.57363) rotate(-64.62574)"
        fill="#2f2e41"
      />
      <circle cx="82.9008" cy="694.62297" r="43.06733" fill="#2f2e41" />
      <rect
        x="63.27519"
        y="728.42266"
        width="13.08374"
        height="23.44171"
        fill="#2f2e41"
      />
      <rect
        x="89.44267"
        y="728.42266"
        width="13.08374"
        height="23.44171"
        fill="#2f2e41"
      />
      <ellipse
        cx="74.17827"
        cy="752.13696"
        rx="10.90314"
        ry="4.08868"
        fill="#2f2e41"
      />
      <ellipse
        cx="100.34581"
        cy="751.59179"
        rx="10.90314"
        ry="4.08868"
        fill="#2f2e41"
      />
      <circle cx="83.99113" cy="683.71987" r="14.71922" fill="#fff" />
      <circle cx="83.99113" cy="683.71987" r="4.90642" fill="#3f3d56" />
      <path
        d="M229.56035,726.4588C226.08287,710.885,237.199,695.14837,254.389,691.31s33.94421,5.67511,37.42169,21.24891-7.91492,21.31768-25.10486,25.156S233.03788,742.03265,229.56035,726.4588Z"
        transform="translate(-188.43634 -71.88718)"
        fill="#e6e6e6"
      />
      <ellipse
        cx="296.42506"
        cy="610.23773"
        rx="21.53369"
        ry="6.76007"
        transform="translate(-567.86257 597.36685) rotate(-69.08217)"
        fill="#2f2e41"
      />
      <circle
        cx="256.61789"
        cy="642.75973"
        r="43.06735"
        transform="translate(-607.38415 721.21814) rotate(-80.78252)"
        fill="#2f2e41"
      />
      <rect
        x="48.55595"
        y="604.67224"
        width="13.08374"
        height="23.44171"
        fill="#2f2e41"
      />
      <rect
        x="74.72343"
        y="604.67224"
        width="13.08374"
        height="23.44171"
        fill="#2f2e41"
      />
      <ellipse
        cx="59.45909"
        cy="628.38653"
        rx="10.90314"
        ry="4.08868"
        fill="#2f2e41"
      />
      <ellipse
        cx="85.62657"
        cy="627.84139"
        rx="10.90314"
        ry="4.08868"
        fill="#2f2e41"
      />
      <circle cx="69.27189" cy="559.96945" r="14.71922" fill="#fff" />
      <circle cx="69.27189" cy="559.96945" r="4.90642" fill="#3f3d56" />
      <path
        d="M214.84117,602.70834c-3.47748-15.57379,7.63867-31.31045,24.82861-35.14883s33.94422,5.67508,37.42169,21.24887-7.91491,21.31765-25.10486,25.156S218.31865,618.28216,214.84117,602.70834Z"
        transform="translate(-188.43634 -71.88718)"
        fill="#3b82f6"
      />
      <ellipse
        cx="208.10974"
        cy="622.77633"
        rx="6.76007"
        ry="21.53368"
        transform="translate(-632.20351 472.04423) rotate(-64.62574)"
        fill="#2f2e41"
      />
      <path
        d="M235.46336,658.29669c0,4.21515,10.85327,12.53857,22.89655,12.53857s23.33514-11.867,23.33514-16.08209-11.29193.81775-23.33514.81775S235.46336,654.08154,235.46336,658.29669Z"
        transform="translate(-188.43634 -71.88718)"
        fill="#fff"
      />
      <ellipse
        cx="435.47266"
        cy="641.35704"
        rx="21.53369"
        ry="6.76007"
        transform="translate(-507.5273 747.25911) rotate(-69.08217)"
        fill="#2f2e41"
      />
      <circle
        cx="404.66549"
        cy="682.87904"
        r="43.06735"
        transform="translate(-522.65242 901.04697) rotate(-80.78252)"
        fill="#2f2e41"
      />
      <polygon
        points="196.604 644.792 209.687 644.792 207.127 672.864 196.604 668.233 196.604 644.792"
        fill="#2f2e41"
      />
      <ellipse
        cx="390.94302"
        cy="743.39302"
        rx="4.08868"
        ry="10.90314"
        transform="translate(-493.58893 201.48401) rotate(-28.23232)"
        fill="#2f2e41"
      />
      <polygon
        points="225.127 644.864 238.211 644.864 235.651 672.937 225.127 668.306 225.127 644.864"
        fill="#2f2e41"
      />
      <ellipse
        cx="419.4668"
        cy="743.46591"
        rx="4.08868"
        ry="10.90314"
        transform="translate(-490.23014 214.9858) rotate(-28.23232)"
        fill="#2f2e41"
      />
      <circle cx="217.31949" cy="600.08875" r="14.71922" fill="#fff" />
      <circle cx="217.31949" cy="600.08875" r="4.90642" fill="#3f3d56" />
      <path
        d="M362.88876,642.82765c-3.47748-15.57379,7.63867-31.31046,24.82862-35.14884s33.94421,5.67508,37.42169,21.24888-7.91492,21.31765-25.10486,25.156S366.36624,658.40147,362.88876,642.82765Z"
        transform="translate(-188.43634 -71.88718)"
        fill="#f2f2f2"
      />
      <ellipse
        cx="359.15733"
        cy="652.89564"
        rx="6.76007"
        ry="21.53368"
        transform="translate(-506.07599 269.2192) rotate(-36.4501)"
        fill="#2f2e41"
      />
      <path
        d="M425.67834,705.08563c0-3.474-8.945-10.334-18.8708-10.334s-19.23228,9.78051-19.23228,13.25448,9.30655-.674,19.23228-.674S425.67834,708.55966,425.67834,705.08563Z"
        transform="translate(-188.43634 -71.88718)"
        fill="#fff"
      />
      <path
        d="M380.56346,646.82224a94.95979,94.95979,0,0,1-95-94.99951c0-.19873.00049-.4082.01172-.60693.291-52.02539,42.90332-94.39307,94.98828-94.39307a95.01491,95.01491,0,0,1,42.18506,180.145A94.10649,94.10649,0,0,1,380.56346,646.82224Zm0-187.99951a93.19834,93.19834,0,0,0-92.98974,92.45557c-.01075.2124-.01026.38281-.01026.54443a93.0003,93.0003,0,0,0,185.15918,12.50684,92.98052,92.98052,0,0,0-92.15918-105.50684Z"
        transform="translate(-188.43634 -71.88718)"
        fill="#3f3d56"
      />
      <path
        d="M423.5861,556.42173,358.56366,518.881a2,2,0,0,0-3,1.73205V595.6945a2,2,0,0,0,3,1.73206l65.02244-37.54073a2,2,0,0,0,0-3.4641L358.56366,518.881a2,2,0,0,0-3,1.73205V595.6945a2,2,0,0,0,3,1.73206l65.02244-37.54073A2,2,0,0,0,423.5861,556.42173Z"
        transform="translate(-188.43634 -71.88718)"
        fill="#3b82f6"
      />
      <ellipse cx="227.12732" cy="747.86444" rx="53" ry="2" fill="#e6e6e6" />
      <ellipse cx="770.12732" cy="728.86444" rx="53" ry="2" fill="#e6e6e6" />
      <path
        d="M958.30982,799.06723,720.64462,670.94417a5.00588,5.00588,0,0,1-2.02855-6.77384l31.78185-58.95459a5.0059,5.0059,0,0,1,6.77385-2.02855L994.837,731.31024a5.00589,5.00589,0,0,1,2.02856,6.77385l-31.78186,58.95458A5.00589,5.00589,0,0,1,958.30982,799.06723ZM756.22271,604.94767a3.00328,3.00328,0,0,0-4.06431,1.21713l-31.78185,58.95458a3.00327,3.00327,0,0,0,1.21713,4.06431l237.6652,128.12306a3.00327,3.00327,0,0,0,4.0643-1.21714L995.105,737.135a3.00329,3.00329,0,0,0-1.21713-4.06431Z"
        transform="translate(-188.43634 -71.88718)"
        fill="#e6e6e6"
      />
      <circle cx="581.28038" cy="581.78679" r="21" fill="#3b82f6" />
      <path
        d="M819.05175,664.36526a3.5,3.5,0,1,0-3.3217,6.16168l145.23958,78.29728a3.5,3.5,0,1,0,3.3217-6.16168Z"
        transform="translate(-188.43634 -71.88718)"
        fill="#e6e6e6"
      />
      <path
        d="M809.08665,682.8503a3.5,3.5,0,1,0-3.32171,6.16168l145.23958,78.29728a3.5,3.5,0,0,0,3.32171-6.16168Z"
        transform="translate(-188.43634 -71.88718)"
        fill="#e6e6e6"
      />
    </svg>
  );
};


MediaPlayerSVG.propTypes = {
  className: PropTypes.string.isRequired,
};

export default MediaPlayerSVG;
